<template>
  <div class="circleBox">
    <div class="topBox">
      <div @click="back()"></div>
      <div>圈子</div>
    </div>
    <div class="backImg">
      <img src="https://xznc-prod.oss-cn-qingdao.aliyuncs.com/static/h5/tuya.png" alt />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.topBox {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.topBox div:nth-child(1) {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topBox div:nth-child(2) {
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 264px;
}
.backImg {
  width: 750px;
  height: 1334px;
  /* background-image: url("../../image/quanzi.png"); */
  /* background-size: 100% 100%; */
  margin-top: 22px;
}
.backImg img {
  width: 750px;
  height: 1334px;
}
</style>
